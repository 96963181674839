@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Yanone+Kaffeesatz:wght@200..700&display=swap');

body {
    background-color: rgba(40,45,60,1);
    font-family: 'Red Hat Display', sans-serif;
}

footer {
    box-sizing: border-box;
    text-align: center;
    padding-top: 20px;
    color: rgba(130,140,160,1);
    font-size: 13px;
    height: 100px;
}

h1 {
    font-weight: normal;
}

ul {
    margin-top: 3px;
    padding-left: 25px;
    font-size: small;
}

.hidden {
    display: none;
}

.title {
    font-size: 34px;
    line-height: 36px;
    margin-top: 10px;
    margin-bottom: 8px;
    text-align: left;
    color: rgba(240,240,250,1);
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-weight: normal;
}

.title-highlight {
    padding: 2px 0px 3px 10px;
    border-radius: 2px;
    background: linear-gradient(90deg,rgba(0,0,0,0) -2%, rgba(10,100,210,0.7) 3%, rgba(0,0,0,0) 95%);
}

.subtitle {
    font-family: "Red Hat Display", sans-serif;
    font-size: 12px;
    line-height: 12px;
}

.app-link {
    color: rgba(90,180,220,1);
}

.social-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: right;
    margin: 5px 0px -45px 0px;
}

.social-link {
    margin: 5px;
    color: rgba(10,100,200,0.8);
    font-size: 30px;
}
.social-link:hover {
    color: rgba(30,140,250,1);
}

.app-content {
    width: 1100px;
    margin: auto;
    min-height: calc(100vh - 130px); /* The combination of this padding and the footer height */
    padding-top: 30px;
}

.app-header {
    text-align: center;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 300;
    color: rgba(235,235,240,1);
    margin-bottom: 50px;
}

.app-logo {
    height: 185px;
    margin-bottom: -25px;
    margin-right: 10px;
    pointer-events: none;
}

.app-title-line-1 {
    font-size: 185px;
    line-height: 180px;
}

.app-title-line-2 {
    font-size: 190px;
    line-height: 180px;
}

/* This is primarily for the border styling */
.app-content-main-border {
    padding-left: 5px;
    padding-top: 5px;
    border-radius: 8px 0px 0px 0px;
    background: linear-gradient(90deg, rgba(220,150,25,0.9) 35%, rgba(0,0,0,0) 95%);
}

.app-content-main {
    border-radius: 6px 0px 0px 0px;
    background-color: rgba(40,45,60,1);
}

.app-content-description {
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
    color: rgba(225,230,238,1);
    padding: 5px 15px 10px 15px;
}

.flip-boxes {
    color: rgba(210,230,245,1);
}

.flip-box {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    width: 300px;
    height: 500px;
    margin: 25px;
    border-radius: 3px;
    cursor: pointer;
    perspective: 300px;
    font-family: "Red Hat Display", sans-serif;
}
.flip-box:hover {
    background-color: rgba(220,220,230,0.1);
}

.flip-box-active {
    animation: flipBoxRotate 1s linear;
    transform: rotate3d(0,1,0,180deg);
}

.flip-box-inactive {
    animation: flipBoxRotateReverse 1s linear;
}

@keyframes flipBoxRotate {
    from {
        transform: rotate3d(0,0,0,180deg);
    }
    to {
        transform: rotate3d(0,1,0,180deg);
    }
}

@keyframes flipBoxRotateReverse {
    from {
        transform: rotate3d(0,1,0,180deg);
    }
    to {
        transform: rotate3d(0,0,0,180deg);
    }
}

.flip-box-front {
    position: absolute;
    box-sizing: border-box;
    text-align: center;
    height: 100%;
    width: 100%;
    border: 2px solid transparent;
    background-color: rgba(255,255,255,0.1);
}

.flip-box-front-content {
    text-align: center;
}

.flip-box-back {
    position: absolute;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border: 2px solid transparent;
    background-color: rgba(220,220,230,0.3);
    transform: rotate3d(0,1,0,180deg);
}

.flip-box-back-content {
    text-align: justify;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
}

.flip-box-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px 10px 10px 10px;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    height: 64px;
    font-family: 'Noto Sans Display', sans-serif;
}

.flip-box-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 23px;
    border-top: 3px solid;
    font-family: 'Noto Sans Display', sans-serif;
}

.flip-box-icons {
    width: 50%;
    height: 50%;
    padding: 25%;
}

.pricing {
    padding-top: 30px;
    padding-left: 15px;
}

.projects {
    padding-top: 30px;
    padding-left: 15px;
}
.projects ul {
    list-style-type: none;
    padding: 0px;
}

.projects ul li {
    color: rgba(210,230,245,1);
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    cursor: pointer;
}
.projects ul li:hover {
    color: rgba(140,160,180,1);
}

.project-content {
    z-index: -999;
    position: fixed;
    box-sizing: border-box;
    visibility: hidden;
    line-height: 24px;
    font-size: 17px;
    padding: 10px;
    width: 1087px; /* subtracting 13 for the border and padding */
}

.project-content-hide {
    transition: height 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease; /* height will be set by js */
    height: 0px;
    padding: 0px;
    opacity: 0;
}   

.project-content-show {
    transition: height 0.5s ease, padding-top 0.5s ease, padding-bottom 0.5s ease; /* height will be set by js */
    opacity: 1;
    position: relative;
    padding: 10px;
    line-height: 24px;
    font-size: 17px;
    font-weight: normal;
    border-radius: 8px;
    color: rgba(215,215,220,1);
    background-color: rgba(240,240,240,0.05);
    overflow: hidden;
    cursor: default;
}

.tech-pill {
    display: inline-block;
    font-weight: bold;
    margin: 4px;
    height: 14px;
    line-height: 14px;
    font-size: 13px;
    padding: 5px;
    border-radius: 4px;
}

.tech-backend {
    color: rgba(255,255,255,1);
    background-color: rgba(10,120,230,1);
}

.tech-frontend {
    color: rgba(50,50,60,1);
    background-color: rgba(245,215,25,1);
}

.tech-other {
    color: rgba(255,255,255,1);
    background-color: rgba(60,130,50,1);
}

@media (max-width: 1200px) {
    .app-header {
        margin-bottom: 40px;
    }

    .app-logo {
        height: 120px;
        margin-bottom: -15px;
    }
    
    .app-title-line-1 {
        font-size: 115px;
        line-height: 110px;
    }
    
    .app-title-line-2 {
        font-size: 120px;
        line-height: 110px;
    }

    .app-content {
        width: 700px;
    }

    .flip-box {
        margin: 10px;
    }

    .project-content {
        width: 687px;
    }
}

@media (max-width: 800px) {
    
    .app-header {
        margin-bottom: 25px;
    }

    .app-logo {
        height: 70px;
        margin-bottom: -10px;
        margin-right: 5px;
    }
    
    .app-title-line-1 {
        font-size: 65px;
        line-height: 60px;
    }
    
    .app-title-line-2 {
        font-size: 70px;
        line-height: 65px;
    }

    .social-links {
        justify-content: center;
        margin: 5px 0px 5px 0px;
    }

    .app-content {
        width: 400px;
    }

    .project-content {
        width: 387px;
    }
}

@media (max-width: 500px) {
    h2 {
        font-size: 26px;
        line-height: 30px;
    }

    .app-logo {
        height: 42px;
        margin-bottom: -5px;
        margin-right: 3px;
    }
    
    .app-title-line-1 {
        font-size: 44px;
        line-height: 45px;
    }
    
    .app-title-line-2 {
        font-size: 45px;
        line-height: 45px;
    }

    .social-links {
        margin: 3px 0px 0px 0px;
    }

    .social-link {
        font-size: 25px;
    }

    .app-content {
        width: 300px;
    }

    .app-content-description {
        font-size: 15px;
        line-height: 22px;
    }

    .app-content {
        width: 300px;
    }

    .project-content {
        width: 287px;
    }

}